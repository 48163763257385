<!--Coming Soon-->
<div class="coming-wrapper bg-sc d-flex align-items-start justify-content-center">
	<div class="coming-soon-wrapper text-center pt-lg-5">
		<div class="mb-5">
			<h2 class="text-capitalize font-5x font-weight-bold">{{'Coming Soon'|translate}}</h2>
		</div>
		<div class="getting-started font-3x mb-5"></div>
		<div class="mb-3">
			<h2 class="text-uppercase font-4x font-weight-bold">{{"We're so close"|translate}}</h2>
		</div>
		<p class="content font-lg">Enjoy a Very Limited Opportunity by subscribing to our newsletter.
			<br> Check out the time remaining on top left, hurry up!</p>
	</div>
</div>