export const environment = {
  production: true,
  
  // apiUrl: 'http://ifd.epionhealth.in/carme/api/' // Latest testing Server For ifd project
  // apiUrl: 'https://ifims.in/carme/api/' // IFD Live server mediprobe
  // apiUrl: 'https://live.ifims.in/carme/api/', // Host name live hapi host
   //  apiUrl: 'http://103.13.115.126/carme/api/', // Host name live hapi host //10.16.113.139
   apiUrl: 'https://investigo.app/carme/api/', // Host name live hapi host 
 
  // ng build --aot --watch --configuration --outputHashing=all
};
