import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GlobalService } from 'app/shared/global.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient, public router: Router, private globals: GlobalService) { }
  // This function loads/displays all Companys from database
  // This gives call to GetAll method from CompanyController
  GetCompany(CompanyValue,CompanyID ,LocationID,IsSuperUser) {
    const customHeaders = this.globals.GetCustomHeadersForGet();
    return this.http.get<any>(environment.apiUrl + 'Company/GetAll?CompanyValue='+CompanyValue
      + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID + '&SuperUser=' +IsSuperUser, { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
        catchError((error: any) => {
          throw error;
        }));
  }

// This function loads/displays data of selected Company
  // Param: ID of Comapny
  // This gives call to Get method from CompanyController
  GetCompanyById(CompanyID) { 
    const customHeaders = this.globals.GetCustomHeadersForGet(); 
    return this.http.get<any>(environment.apiUrl + 'Company/GetCompanyByID?CompanyID=' + CompanyID, { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
        catchError((error: any) => {
          throw error;
        }));
  }
  //to print company in crystal report
  GetCompanyDetailsReport(CompanyID, LocationID)
  {
    const customHeaders = this.globals.GetCustomHeadersForGet();
    return this.http.get<any>(environment.apiUrl + 'Company/GetCompanyDetailsReport?CompanyID=' + CompanyID + '&LocationID=' + LocationID , { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
        catchError((error: any) => {
          throw error;
        }));

  }
  
//get max company id from database to generate company code
  GetCompanyCode() {
    const customHeaders = this.globals.GetCustomHeadersForGet();
    return this.http.get<any>(environment.apiUrl + 'Company/GetCompanyCode', { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
        catchError((error: any) => {
          throw error;
        }));
  }

// This function Saves/updates data of Company
  // Param: Object of Company
  // This gives call to post method from ComapanyController


  saveCompany(Company) {
    const customHeaders = this.globals.GetCustomHeadersForPost();
    return this.http.post<any>(environment.apiUrl + 'Company', Company, { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
        catchError((error: any) => {
          throw error;
        }));
  }

  // This method Deactivates Company on the basis of id.
  // This method gives call to DeactivateCompany function from wardService
  // Parameter : Id of selected Company
  DeactivateCompany(CompanyID,CompanyName, UserName, activate) {
    const customHeaders = this.globals.GetCustomHeadersForGet();
    return this.http.delete<any>(environment.apiUrl + 'Company/Delete?CompanyID=' + CompanyID + '&CompanyName='+ CompanyName + '&UserName='+ UserName +'&activate=' + activate,  { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
        catchError((error: any) => {
          throw error;
        }));
  }
}
