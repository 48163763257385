import { Injectable } from '@angular/core';
import { GlobalService } from 'app/shared/global.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})

export class PageTitleService {
 public title: BehaviorSubject<string> = new BehaviorSubject<string>(null);

 constructor(private globals: GlobalService) { }
//  setTitle(value: string) {
//   this.title.next(value);
//  }


 setTitle(value: string) {
    this.globals.title = value;
 }
}
