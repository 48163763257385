import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({
   providedIn: 'root'
})
export class CommonService {

   constructor(private http: HttpClient, public router: Router, private globals: GlobalService) { }

   //  Loads all Client
   getClientList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetWorkType() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetWorkType', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   getPaymentSequence() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/getPaymentSequence', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetPayableItems() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPayableItems', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetInvestigationCheckpoints() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInvestigationCheckpoints', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetCompanyList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCompanyList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   //  Loads all Locations using CompanyID
   getLocationsList(CompanyID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLocationList?CompanyID=' + CompanyID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads Control Name using ClientID and ComponentID
   getControlName(CompanyID, ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetControlName?CompanyID=' + CompanyID + '&componentID=' + ComponentID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all appointment from database
   // This gives call to Get method from AppointmentController
   getAppointment(FromDate, ToDate, CompanyID, LocationID, ComponentID, PreviousDateTransaction) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Appointment/GetByFilters?FromDate=' + FromDate + '&ToDate=' + ToDate
         + '&CompanyID=' + CompanyID + '&locationID=' + LocationID + '&componentID=' + ComponentID
         + '&previousDateTransaction=' + PreviousDateTransaction, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Get Menu Visibilty For user By Using User ID
   GetMenuVisbleForUserByUserID(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetMenuVisibility?userID=' + UserID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets ReceiptBook List
   getAllReceiptBookList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllReceiptBookList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets Client Name And Location Name By Using ID
   getClientNameLocationName(CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientLocationName?CompanyID=' + CompanyID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Check valid token or not
   GetCheckValidToken(UserID) {
      const data = { 'token': sessionStorage.getItem('token'), UserID: UserID };
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'Common/GetCheckValidToken', data, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all LoginTemplate List
   getLoginTemplateList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'User/GetLoginTemplateList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
    // Getting Client Configuration Details Using CLient Location
    GetClientConfigurationDetails(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientConfigurationDetails?ClientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   //  Loads all locations
   getLocationList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLocationList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting All Common Department List
   GetAllDepartmentList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllDepartmentList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Departments
   getDepartmentList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDepartmentList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Departments for Admission/visitMark
   GetAdmissionVisitMarkDepartmentList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAdmissionVisitMarkDepartmentList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   //  Loads all employee
   getEmploeeList(CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetEmployeeList?CompanyID=' + CompanyID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   //  Loads all doctor
   getDoctorList(ClietnID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorList?clientID=' + ClietnID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all doctor list using doctor type
   getDoctorListByDoctorType(DoctorType, ClietnID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorListByDoctorType?doctorType=' + DoctorType +
         '&clientID=' + ClietnID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all doctor
   GetComponentList(CompanyID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentList?CompanyID=' + CompanyID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   getComponent() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentList',
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all User
   getUserList(suser) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetUserList?user=' + suser, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   //  Loads all User Rights for Details
   getRightsList(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRightsList/?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets all Cost Centre
   getCostCentreList(CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCostCentreList?CompanyID=' + CompanyID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Gets ReceiptBook List
   getReceiptBookList(CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReceiptBookList?CompanyID=' + CompanyID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of sponsor type
   getSponsorTypeList(sSponsorApplicable) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorTypeList?sSponsorApplicable=' + sSponsorApplicable,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of Group
   getGroupList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetGroupList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of Class
   getClassList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClassList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Service List By Using Service Type
   getServiceListByServiceType(ServiceType: string) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetServiceListByServiceType?ServiceType=' + ServiceType,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting details of group by using ClientID and ClassID
   getGroupListForClient(TariffID: number, ClassID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetGroupListForClient?TariffID=' + TariffID + '&ClassID=' + ClassID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(catchError((error: any) => {
            throw error;
         }));
   }

   // Getting details of Service
   GetClientServiceList(TariffID: number, ClassID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientServiceList?TariffID=' + TariffID +
         '&ClassID=' + ClassID, { headers: new HttpHeaders(customHeaders) })
         .pipe(catchError((error: any) => {
            throw error;
         }));
   }

   // Getting details of Service
   GetClientServiceListForReport(TariffIds: string) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientServiceListForReport?TariffIds=' + TariffIds, { headers: new HttpHeaders(customHeaders) })
         .pipe(catchError((error: any) => {
            throw error;
         }));
   }

   //  Loads all Sponsor
   getSponsorList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Sponsor By Selction Of SponsorType For Tariff
   getSponsorBySponsorTypeForTariff(SponsorTypeID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorBySponsorTypeForTariff?sponsorTypeID=' + SponsorTypeID
         , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Sponsor By Selction Of SponsorType
   getSponsorBySponsorType(SponsorTypeID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorBySponsorType?sponsorTypeID=' + SponsorTypeID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Tariff By Using SponsorTypeID , SponsorID , ClientID
   GetTariffBySponsorTypeandSponsor(SponsorTypeID, SponsorID, ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTariffBySponsorTypeandSponsor?sponsorTypeID=' + SponsorTypeID
         + '&sponsorID=' + SponsorID + '&clientID=' + ClientID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Service By Selction Of Group
   getServiceByGroupTariff(Group, TariffID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetServiceByGroupTariff?group=' + Group
         + '&tariffID=' + TariffID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Components
   getComponentList(CompanyID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentList?CompanyID=' + CompanyID
         , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Load all Control List Using ComponentID
   GetComponentControlList(ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetComponentControlList?ComponentID=' + ComponentID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all validationType
   getValidationTypeList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetValidationTypeList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads validation Control Name
   getValidation(CompanyID, ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetValidation?CompanyID=' + CompanyID
         + '&componentID=' + ComponentID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Patients
   getPatient(FirstName, MiddleName, LastName, OPDNo, RegistrationNo, MobileNumber,
      RegistrationDate, OpIpFlag, IsDocterzClient, SmartSearch, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'common/GetPatient?firstname=' + FirstName + '&middlename='
         + MiddleName + '&lastname=' + LastName + '&opd_admno=' + OPDNo + '&regno=' + RegistrationNo
         + '&mobile=' + MobileNumber + '&regdate=' + RegistrationDate + '&opIpFlag=' + OpIpFlag
         + '&isDocterzClient=' + IsDocterzClient + '&smartSearch=' + SmartSearch
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // get doctors by department Wise
   getDoctorByDepartment(DepartmentID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorByDepartment?departmentID=' + DepartmentID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   getCurrentDateTime() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCurrentDateTime', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Get Global Date
   getCurrentGlobalDate() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCurrentGlobalDate', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // get department by doctor wise
   getDepartmentByDoctor(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDepartmentByDoctor/?deptID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Loads add referring Doctors
   getRefDoctorList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRefDoctorList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all Tariff
   getTariffList(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTariffList?clientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   //  Loads selected patient
   getSelectedPatientList(PatientID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSelectedPatientList?patientID=' + PatientID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used to get sponsors applicable to client and default sponsor for that client
   GetSponsorApplicableAndDefaultSponsor(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSponsorApplicableAndDefaultSponsor?ClientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) }).pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used to get Department and Doctor for that client
   GetDefaultDeprtmentAndDefaultDoctor(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDefaultDeprtmentAndDefaultDoctor?ClientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) }).pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used to get Password
   GetPassword(ModuleName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPassword?moduleName=' + ModuleName,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Patient Details From Common Controller
   getPatientDetails(OpIpID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientDetails?opIpID=' + OpIpID
         + '&opIpFlag=' + OpIpFlag, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Current Receipt Book Number For Inventory By Using ProcessType,StoreID, ClientID and LocationID
   GetInventoryReceiptBookNumber(ReceiptBookID: number, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryReceiptBookNumber?ReceiptBookID=' + ReceiptBookID +
         '&ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Removing token
   RemoveToken(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/RemoveToken?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Checking User is doctor or not by passing Userid , ClientId and LocationID
   IsUserDoctor(UserID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/IsUserDoctor?userID=' + UserID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Dose List from databases
   // This gives call to Get method from CommonController
   getDose() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoseList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all When List from databases
   // This gives call to GetWhen method from CommonController
   getWhen(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetWhen?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Duration List from databases
   // This gives call to GetDuration method from CommonController
   getDuration(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDuration?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting User Is Doctor Or Not and Default Language
   GetUserDoctorOrNotAndDefaultLanguage(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetUserDoctorOrNotAndDefaultLanguage?UserID=' + UserID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Patient Details using PatientID , ClientID and LocationID
   GetPatientData(PatientID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientData?patientID=' + PatientID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Pathology GroupID
   GetPathologyGroupID() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPathologyGroupID', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Radiology GroupID
   GetRadiologyGroupID() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRadiologyGroupID', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Cash TariffID Using ClientID
   GetTariffID(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTariffID?ClientID=' + ClientID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Advance related details using PatientID
   GetPatientAdvanceDetails(PatientID, OpIpID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientAdvanceDetails?patientID=' + PatientID
         + '&opIpID=' + OpIpID + '&opIpFlag=' + OpIpFlag, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Building from database
   // This gives call to GetBuildingList method from CommonController
   GetBuildingList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetBuildingList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   // This function loads/displays all Floor from database
   // This gives call to GetFloorList method from CommonController
   GetFloorList(BuildingID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetFloorList?buildingID=' + BuildingID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Ward from database
   // This gives call to GetWardList method from CommonController
   GetWardList(FloorID, WardTypeID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetWardList?floorID=' + FloorID +
         '&wardTypeID=' + WardTypeID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all Room from database
   // This gives call to GetRoomList method from CommonController
   GetRoomList(WardID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetRoomList?wardID=' + WardID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function Gets City Name from database
   // This gives call to GetDefaultCity method from CommonController
   GetDefaultCity(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDefaultCity?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function Gets Latest Registration No from database
   // This gives call to GetLatestRegNo method from CommonController
   GetLatestRegNo(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLatestRegNo?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Admission Details Of Patient Using AdmissionID
   GetAdmissionDetailsOfPatient(AdmissionID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAdmissionDetailsOfPatient?admissionID=' + AdmissionID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting IPD Patient List
   GetIPDPatientList(FirstName, MiddleName, LastName, ADMNo, RegNo, GSM1, FromDate, ToDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetIPDPatientList?firstName=' + FirstName + '&middleName=' + MiddleName +
         '&lastName=' + LastName + '&admNo=' + ADMNo + '&regno=' + RegNo + '&gsm1=' + GSM1 + '&fromDate=' + FromDate
         + '&toDate=' + ToDate + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Ward List
   GetClientWardList(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientWardList?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting ReceiptBookName From Common Controller
   getReceiptBookName(ReceiptBookID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReceiptBookName?receiptBookID=' + ReceiptBookID +
         '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Last Appointment Or Admission Sponsor Details
   GetLastSponsorDetails(PatientID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLastSponsorDetails?patientID=' + PatientID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // // Getting Insurance List
   // GetInsuranceList(ClientID, LocationID) {
   //    const customHeaders = this.globals.GetCustomHeadersForGet();
   //    return this.http.get<any>(environment.apiUrl + 'Common/GetInsuranceList?clientID=' + ClientID
   //       + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
   //       .pipe(
   //          catchError((error: any) => {
   //             throw error;
   //          }));
   // }

   // Getting Corporate List
   // GetCorporateList(ClientID, LocationID) {
   //    const customHeaders = this.globals.GetCustomHeadersForGet();
   //    return this.http.get<any>(environment.apiUrl + 'Common/GetCorporateList?clientID=' + ClientID
   //       + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
   //       .pipe(
   //          catchError((error: any) => {
   //             throw error;
   //          }));
   // }
                    
   // This function is used to get all fonts installed in the systems
   GetInstalledFontName() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInstalledFontName', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Report List
   GetReportList(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReportList?clientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting ClassList By Client Location
   GetClassListByClientLocation(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClassListByClientLocation?clientID=' + ClientID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Configuration Details Using Company Location
   GetConfigurationDetails(CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetConfigurationDetails?CompanyID=' + CompanyID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Company Configuration Details Using Company Location
   GetCompanyConfigurationDetails(CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCompanyConfigurationDetails?CompanyID=' + CompanyID
         + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting Tax List For Inventory
   GetTaxList(CompanyID,LocationID,InvoiceGSTNo) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTaxListForInventory?CompanyID=' + CompanyID
         + '&LocationID=' + LocationID + '&InvoiceGSTNo=' + InvoiceGSTNo , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Parameters List
   GetDefaultPathologyTypeList(ClientID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDefaultPathologyTypeList?clientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) }).pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Parameters List
   GetPathParamList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPathParamList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting SubTest List
   GetSubTestList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSubTestList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Test Values Using PathTestID / PathSubTestID / PathParamID
   GetTestValues(PathTestID, PathSubTestID, PathParamID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTestValues?pathTestID=' + PathTestID
         + '&PathSubTestID=' + PathSubTestID + '&PathParamID=' + PathParamID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This method is used to get total no of doctor users for selected client
   GetTotalDoctorUserCount(ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTotalDoctorUserCount?ClientID=' + ClientID
         + '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This gives call to GetAvailableBedDetails method from CommonController
   GetAvailableBedDetails(ClientBuildingID, ClientWardID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAvailableBedDetails?clientBuildingID=' + ClientBuildingID +
         '&clientWardID=' + ClientWardID + '&clientID=' + ClientID + '&locationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Ward List On Selection Of Building
   GetWardsList(BuildingID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetWardsList?buildingID=' + BuildingID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   PrintAppointmentScheduler(SelectedDate, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/PrintAppointmentScheduler?SelectedDate=' + SelectedDate
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all frequency List from mstcommon table
   // This gives call to GetFrequency method from CommonController
   GetFrequency(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetFrequency?userID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting InventoryCommon List Using Inventory Common Name
   GetInventoryCommon(InventoryCommonName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryCommonName?InventoryCommonName=' +
         InventoryCommonName, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting InventoryCommon List Using Inventory Common Name
   // GetTaxType() {
   //    const customHeaders = this.globals.GetCustomHeadersForGet();
   //    return this.http.get<any>(environment.apiUrl + 'Common/GetTaxType', { headers: new HttpHeaders(customHeaders) })
   //       .pipe(
   //          catchError((error: any) => {
   //             throw error;
   //          }));
   // }

   GetTaxType(type) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTaxType?type=' + type, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Stores List
   GetStoresList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetStoresList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Supplier List
   GetSupplierList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSupplierList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Inventory Department List Using ClientID and LocationID
   GetInventoryDepartmentList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryDepartmentList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Inventory Items List
   GetInventoryItemList(ItemName, SelectedItemID, StoreID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetInventoryItemList?value=' + ItemName
         + '&SelectedItemID=' + SelectedItemID + '&StoreID=' + StoreID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Tax Value Using ItemID and TaxID
   GetItemTaxValue(ItemID, TaxID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetItemTaxValue?ItemID=' + ItemID
         + '&TaxID=' + TaxID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Latest Numbers For Inventory
   GetLatestInventoryNumber(InventoryType, Date) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetLatestInventoryNumber?InventoryType=' + InventoryType
         + '&Date=' + Date, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function is used  to get discharge details of selected patient
   GetPatientDischargeDetails(AdmissionID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPatientDischargeDetails?AdmissionID=' + AdmissionID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This gives call to Get method from BillController
   getServiceRate(ServiceID, TariffID, ClassID, PatientID, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetServiceRate?serviceID=' + ServiceID
         + '&tariffID=' + TariffID + '&classID=' + ClassID + '&patientID=' + PatientID
         + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Selected Group Pathology / Radiology Or Not
   GetIsPathlogyRadiologyGroupOrNot(GroupID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetIsPathlogyRadiologyGroupOrNot?GroupID=' + GroupID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Inventory Items List
   GetClientInventoryItemList(ItemName, SelectedItemID, StoreID, ClientID, LocationID, Type, PharmacyLocation) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientInventoryItemList?value=' + ItemName
         + '&SelectedItemID=' + SelectedItemID + '&StoreID=' + StoreID + '&ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&Type=' + Type + '&PharmacyLocation=' + PharmacyLocation, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Balance Quantity For Selected Item Using StoreID, ItemID, ClientID and LocationID
   GetBalanceQuantityOfSelectedItem(StoreID: number, ItemID: number, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      let params = new HttpParams();
      params = params.append("StoreID", StoreID);
      params = params.append("ItemID", ItemID);
      params = params.append("ClientID", ClientID);
      params = params.append("LocationID", LocationID);
      return this.http.get<any>(environment.apiUrl + 'Common/GetBalanceQuantityOfSelectedItem',
         { headers: new HttpHeaders(customHeaders), params: params })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Doctor Request Charges of OpIpID and OpIpFlag
   GetDoctorRequestCharges(OpIpID, OpIpFlag) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDoctorRequestCharges?OpIpID=' + OpIpID
         + '&OpIpFlag=' + OpIpFlag, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Selected Option (OPD , IPD, Staff) List Using Option, ClientID, LocationID , FormDate And ToDate
   GetSelectedOpdIpdDoctorStaffList(OptionType, ClientID, LocationID, FromDate, ToDate) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetSelectedOpdIpdDoctorStaffList?OptionType=' + OptionType
         + '&ClientID=' + ClientID + '&LocationID=' + LocationID + '&FromDate=' + FromDate + '&ToDate=' + ToDate,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function gets Print Opd / Ipd Patient Advance from database
   // This gives call to GetTotalAdvance method from CommonController
   GetTotalAdvance(OpIpID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTotalAdvance?opIpID=' + OpIpID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function gets Print Opd / Ipd Patient Advance from database
   // This gives call to GetTotalAdvance method from CommonController
   ViewAdvanceReceipt(PaymentID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/ViewAdvanceReceipt?paymentID=' + PaymentID
         + '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }



   // Printing Refund of Advance By Passing OpIpID and OpIpFlag
   GetTotalRefundAdvance(OpIpID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTotalRefundAdvance?opIpID=' + OpIpID +
         '&opIpFlag=' + OpIpFlag + '&clientID=' + ClientID + '&locationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Printing Bill Settlement Report
   // This gives call to GetPrintSettlementReport method from CommonController
   GetPrintSettlementReport(OpIpID, BillID, OpIpFlag, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPrintSettlementReport?OpIpID=' + OpIpID +
         '&BillID=' + BillID + '&OpIpFlag=' + OpIpFlag + '&ClientID=' + ClientID + '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting User's DoctorID using UserID
   // This gives call to GetUsersDoctorID method from CommonController
   GetUsersDoctorID(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetUsersDoctorID?UserID=' + UserID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // This function loads/displays all menu for user
   // This gives call to GetMenuItems method from CommonController
   GetMenuItems(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetMenuItems?UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   //  search patient on given critetia
   PatientSmartSearch(SearchTerm, ClientID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'common/PatientSmartSearch?SearchTerm=' + SearchTerm + '&ClientID=' + ClientID
         + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Common Template List Using ClientID, LocationID and ComponentID
   GetClientCommonTemplateList(ClientID, LocationID, ComponentID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientCommonTemplateList?ClientID=' + ClientID
         + '&LocationID=' + LocationID + '&ComponentID=' + ComponentID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }


   // Getting Inventory Department List Using ClientID and LocationID
   GetAllKPIList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllKPIList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
 // Get All Report List by licence
 GetAllReportList(CompanyID, LocationID) {
   const customHeaders = this.globals.GetCustomHeadersForGet();
   return this.http.get<any>(environment.apiUrl + 'Common/GetAllReportList?CompanyID=' + CompanyID
      + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
      .pipe(
         catchError((error: any) => {
            throw error;
         }));
}
   // Getting Client Tariff, Sponsor, SponsorType List Using ClientID
   GetClientTariffSponsorSponsorTypeList(ClientID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientTariffSponsorSponsorTypeList?ClientID=' + ClientID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Checked Is User Token Same Or Not
   GetCheckUserTokenValidOrNot() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCheckUserTokenValidOrNot', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Operation Theatre Smart Search Result
   OTPatientSmartSearch(SearchTerm: string, OpIpFlag: boolean, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/OTPatientSmartSearch?SearchTerm=' + SearchTerm + '&OpIpFlag=' + OpIpFlag +
         '&ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Template List By Passing Category Name , ClientID and LocationID
   GetTemplateListByCategoryName(CategoryName: string, DoctorIDs: string, DepartmentIDs: string, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTemplateListByCategoryName?CategoryName=' + CategoryName +
         '&DoctorIDs=' + DoctorIDs + '&DepartmentIDs=' + DepartmentIDs + '&ClientID=' + ClientID + '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Template Details By Passing Template Group Name , ClientID and LocationID
   GetTemplateDetailsByTemplateGroupName(TemplateGroupName: string, ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTemplateDetailsByTemplateGroupName?TemplateGroupName=' + TemplateGroupName
         + '&ClientID=' + ClientID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Checking user password is matching or not
   IsPasswordMatchingForUser(UserName, Password) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/IsPasswordMatchingForUser?UserName=' + UserName
         + '&Password=' + Password, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Approving User Details
   UserApproveDetails(UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/UserApproveDetails?userID=' + UserID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Client Building Floor Ward Room Bed List Using ClientID
   GetClientBuildingFloorWardRoomBed(ClientID: number, LocationID: number) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientBuildingFloorWardRoomBed?ClientID=' + ClientID +
         '&LocationID=' + LocationID,
         { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Asset Stores from mstconfiguration
   GetAssetStoreID() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAssetStoreID', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Pathology Report Status
   GetPathologyReportStatus(AppAdmNo: string, ClientID: number, LocationID: number) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append("AppAdmNo", AppAdmNo);
      httpParams = httpParams.append("ClientID", ClientID);
      httpParams = httpParams.append("LocationID", LocationID);
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPathologyReportStatus',
         { headers: new HttpHeaders(customHeaders), params: httpParams })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting Pathology Report
   GetPathologyReport(AppAdmNo: string, ClientID: number, LocationID: number) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append("AppAdmNo", AppAdmNo);
      httpParams = httpParams.append("ClientID", ClientID);
      httpParams = httpParams.append("LocationID", LocationID);
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetPathologyReport',
         { headers: new HttpHeaders(customHeaders), params: httpParams })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   // Getting  list of Activate Document Name List
   GetActivateDocumentNameList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetActivateDocumentNameList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting  list of Activate Company List
   GetActivateCompanyList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetActivateCompanyList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
    // Getting  list of Activate Company List
    GetCompanyBankList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCompanyBankList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
  //Getting excel file details
  GetReportExel(fromdate, todate, CompanyID, LocationID, ReportName, FilterCompanyID, FilterBankID) {
   const customHeaders = this.globals.GetCustomHeadersForGet();
   return this.http.get<any>(environment.apiUrl + 'Common/GetReportExel?fromdate=' + fromdate +
      '&todate=' + todate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID +
      '&ReportName=' + ReportName + '&FilterCompanyID=' + FilterCompanyID + '&FilterBankID=' + FilterBankID, { headers: new HttpHeaders(customHeaders) })
      .pipe(
         catchError((error: any) => {
            throw error;
         }));
}
   // Getting  list of Activate Client List
   GetActivateClientList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetActivateClientList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
//Getting all Activate TAP Client list
   GetActivateTPAClientList()
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetActivateTPAClientList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting  list of Activate Client Manager List
   GetActivateClientManagerList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetActivateClientManagerList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetActivateTriggerList() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetActivateTriggerList', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // Getting  list of All State,Distinct,taluka,Area from address table
   // GetAllState() {
   //    const customHeaders = this.globals.GetCustomHeadersForGet();
   //    return this.http.get<any>(environment.apiUrl + 'Common/GetAllState', { headers: new HttpHeaders(customHeaders) })
   //       .pipe(
   //          catchError((error: any) => {
   //             throw error;
   //          }));
   // }
   GetAllState() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllState', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetAllDistrict(State) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllDistrict?State=' + State, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetAllTaluka(District) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllTaluka?District=' + District, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetAllCity(Taluka) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllCity?Taluka=' + Taluka, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetAllArea(City) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllArea?City=' + City, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetAllPin(Area) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllPin?Area=' + Area, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));

   }
   GetDesignation(DesignationID,CompanyID,LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDesignation?DesignationID=' + DesignationID + '&CompanyID=' + CompanyID +
       '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetAllDesignation(DesignationID,CompanyID,LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllDesignation?DesignationID=' + DesignationID + '&CompanyID=' + CompanyID +
       '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

  

   GetCliamHandlerManagerTemleaderVHead(ClientID,CompanyID,LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCliamHandlerManagerTemleaderVHead?ClientID=' + ClientID +
         '&CompanyID=' + CompanyID + '&LocationID=' + LocationID , { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetReportQC() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReportQC', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetAtachementFile(filename, type, ClaimNo, CompanyID, LocationID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAtachementFile?filename=' + filename +
         '&type=' + type + '&ClaimNo=' + ClaimNo + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetEmployeeDetails(EmployeeID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetEmployeeDetails?EmployeeID=' + EmployeeID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetHospitalDetailsbySearchTerm(SearchTerm) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetHospitalDetailsbySearchTerm?SearchTerm='+SearchTerm, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetHospitalDetails() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetHospitalDetails', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

   GetAllAddress() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllAddress', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetHospitalDetailsByName(HospitalName) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetHospitalDetailsByName?HospitalName=' + HospitalName, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   //Getting Configuration values from mstconfiguration tables.
   GetMstConfigurationDetails() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetMstConfigurationDetails', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetDistinctTriggerName()
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDistinctTriggerName', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
// Click To Call
GetClickToCall(ClickTocall) {
   const customHeaders = this.globals.GetCustomHeadersForPost();
   return this.http.post<any>(environment.apiUrl + 'Common/GetClickToCall', ClickTocall, { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
         catchError((error: any) => {
            throw error;
         }));
}

GetClouserSearchTerm(SearchTerm,SearchType)
{
   const customHeaders = this.globals.GetCustomHeadersForGet();
   return this.http.get<any>(environment.apiUrl + 'Common/GetClouserSearchTerm?SearchTerm=' + SearchTerm + '&SearchType=' + SearchType, { headers: new HttpHeaders(customHeaders) })
      .pipe(
         catchError((error: any) => {
            throw error;
         }));
}

GetTemplateName()
{
   const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetTemplateName', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
}
   // Getting ReceitpBook Name And SponsorType
   GetReceiptBookDetails(OpIp, SponsorType, TargetPage): string {
      let receiptbook = '';

      // OPD Or IPD
      if (OpIp === true) {
         receiptbook += 'OPD';
      } else {
         receiptbook += 'IPD';
      }

      // Sponsor Type   || +SponsorType === 17 || +SponsorType === 19
      if (+SponsorType === 1) {
         receiptbook += ' Cash';
      } else {
         receiptbook += ' Invoice';
      }

      // Target Page
      if (TargetPage === 'Bill') {
         receiptbook += '';
      } else if (TargetPage === 'Payment') {
         receiptbook += ' Settlement';
      } else if (TargetPage === 'Advance') {
         receiptbook = '';
         if (OpIp === true) {
            receiptbook += 'OPD Advance';
         } else {
            receiptbook += 'IPD Advance';
         }
      } else if (TargetPage === 'Refund Advance') {
         receiptbook = '';
         if (OpIp === true) {
            receiptbook += 'OPD Refund Advance';
         } else {
            receiptbook += 'IPD Refund Advance';
         }
      } else if (TargetPage === 'Refund Bill') {
         receiptbook = '';
         if (OpIp === true) {
            receiptbook += 'OPD Refund Bill';
         } else {
            receiptbook += 'IPD Refund Bill';
         }
      }

      // Setting Globals SponsorType
      SponsorType = '';
      const receiptbookname = receiptbook.replace(/\s+/g, '');
      SponsorType = this.globals[receiptbookname];

      return receiptbook + ',' + SponsorType;
   }
    //To get Bucket list for user
   GetUserBucketList(UserID)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetUserBucketList?UserID='+ UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   // to get client Remark and client trigger for selected case
   GetClientTriggerRemark(CaseDetailsID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetClientTriggerRemark?CaseDetailsID=' + CaseDetailsID , { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
         catchError((error: any) => {
            throw error;
         }));
   }
   CheckUserAreaManagerOrNot(TeamLeaderID,EmployeeID)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/CheckUserAreaManagerOrNot?TeamLeaderID=' + TeamLeaderID + '&EmployeeID=' + EmployeeID, { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
         catchError((error: any) => {
            throw error;
         }));
   }
   GetAllCaseDetailsColumns()
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllCaseDetailsColumns', { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
         catchError((error: any) => {
            throw error;
         }));
   }
   GetAllClouserColumns()
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAllClouserColumns', { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
         catchError((error: any) => {
            throw error;
         }));
   }

   UploadFile(formData) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      const endpoint = environment.apiUrl + 'Common/UploadFile';
      return this.http
        .post<any>(endpoint, formData)
        .pipe(map(data => data),
          catchError((error: any) => {
            throw error;
          }));
    }
    GetDistinctZone()
    {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDistinctZone', { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
         catchError((error: any) => {
            throw error;
         }));
    }
    GetDashboardTemplateName()
    {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetDashboardTemplateName', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
    }
    GetReportTemplateName()
    {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetReportTemplateName', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
    }
    
 // getting authentication rights of component for the selected user
   GetAuthenticationTypeComponent(ComponentPath, UserID) {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetAuthenticationTypeComponent?ComponentPath=' + ComponentPath
         + '&UserID=' + UserID, { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }

    // getting employeedetails
    GetEmployeeDetail() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetEmployeeDetails', { headers: new HttpHeaders(customHeaders) })
         .pipe(
            catchError((error: any) => {
               throw error;
            }));
   }
   GetVIManagerID(CaseDetailsID)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetVIManagerID?CaseDetailsID=' + CaseDetailsID , { headers: new HttpHeaders(customHeaders) })
      .pipe(map(data => data),
         catchError((error: any) => {
            throw error;
         }));
   }
   GetFieldNameList()
   {
     const customHeaders = this.globals.GetCustomHeadersForGet();
     return this.http.get<any>(environment.apiUrl + 'Common/GetFieldNameList', { headers: new HttpHeaders(customHeaders) })
       .pipe(map(data => data),
         catchError((error: any) => {
           throw error;
         }));
   }
   GetDynamicData(fromdate, todate ,CompanyID,LocationID)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
     return this.http.get<any>(environment.apiUrl + 'Common/GetDynamicData?fromdate=' + fromdate + '&todate=' + todate + '&CompanyID=' + CompanyID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
       .pipe(map(data => data),
         catchError((error: any) => {
           throw error;
         }));
   }
   SendMailToCalimManager(fromDate,toDate,CompanyID,LocationID)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
     return this.http.get<any>(environment.apiUrl + 'Common/SendMailToCalimManager?fromDate=' + fromDate + '&toDate=' + toDate +
       '&CompanyID=' + CompanyID + '&LocationID=' + LocationID, { headers: new HttpHeaders(customHeaders) })
       .pipe(map(data => data),
         catchError((error: any) => {
           throw error;
         }));
   }
   GetColumnNames(CompanyID,LocationID,TableName)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetColumnNames?CompanyID=' + CompanyID + '&LocationID=' + LocationID + '&TableName=' + TableName, { headers: new HttpHeaders(customHeaders) })
        .pipe(map(data => data),
          catchError((error: any) => {
            throw error;
          }));
   }

   //GetEmailTemplate
   GetEmailTemplate(CompanyID,LocationID)
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetEmailTemplate?CompanyID=' + CompanyID + '&LocationID=' + LocationID , { headers: new HttpHeaders(customHeaders) })
        .pipe(map(data => data),
          catchError((error: any) => {
            throw error;
          }));
   }
   GetCriteriaKeywords()
   {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Common/GetCriteriaKeywords' , { headers: new HttpHeaders(customHeaders) })
        .pipe(map(data => data),
          catchError((error: any) => {
            throw error;
          }));
   }

}
