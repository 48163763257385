<!--Coming Soon V2-->
<div class="comingsoon-wrap bg-white">
	<div class="row no-gutters comingsoon-row align-items-stretch">
		<div class="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
			<div class="comingsoon-image h-100">
			</div>
		</div>
		<div class="col-12 col-sm-12 col-md-12 col-lg-6 align-items-center justify-content-center d-flex">
			<div class="text-center px-5">
				<div class="text-center mb-4">
					<h2><span class="font-3x font-weight-light text-uppercase">{{'Coming Soon'|translate}}</span></h2>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-10 col-lg-10 mx-auto">
						<div class="text-center mb-5">
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</p>
						</div>
					</div>
				</div>
				<div class="counter-wrapper text-center mb-5 px-5">
					<div class="row align-items-center">
						<div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
							<div class="text-center">
								<h4 class="mb-2 font-weight-normal"><span class="font-2x">{{days}}</span></h4>
								<p>Days</p>
							</div>
						</div>
						<div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
							<div class="text-center">
								<h4 class="mb-2 font-weight-normal"><span class="font-2x">{{hours}}</span></h4>
								<p>Hours</p>
							</div>
						</div>
						<div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
							<div class="text-center">
								<h4 class="mb-2 font-weight-normal"><span class="font-2x">{{minutes}}</span></h4>
								<p>Minutes</p>
							</div>
						</div>
						<div class="mb-2 col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
							<div class="text-center">
								<h4 class="mb-2 font-weight-normal"><span class="font-2x">{{seconds}}</span></h4>
								<p>Seconds</p>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-4 text-center">
					<span>Subscribe For Your Early Access Invite.</span>
				</div>
				<div class="text-center d-flex align-items-stretch justify-content-center">
					<div class="mr-2">
						<input class="form-control" type="email" placeholder="Enter Email">
					</div>
					<div>
						<button class="btn btn-dark h-100 text-nowrap">Subscribe</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>