<!--Forgot Password-->
<div class="forgot-password d-flex align-items-center justify-content-between">
 <div class="login-block mx-auto text-center position-relative shadow-box">
  <div class="login-icon mb-5">
   <span class="square-100"><i class="fa fa-unlock-alt"></i></span>
  </div>
  <div class="mb-5">
   <h2 class="text-capitalize font-2x mb-3">{{'Recover Password'|translate}}</h2>
   <p>{{"Enter your email and we'll send you instructions on how to reset your password"|translate}}</p>
  </div>
  <form #form="ngForm" (ngSubmit)="forgetPassword(form.value)" class="login-form" action="javascript:void(0)"
   method="post">
   <div class="form-group">
    <input type="email" name="email" class="form-control session-input-font input-square" [(ngModel)]="email"
     placeholder="Your email" required>
   </div>
   <div class="mb-4">
    <button type="submit" class="btn btn-primary btn-block btn-square btn-lg">{{'Send Me Password'|translate}}</button>
   </div>
   <div class="row">
    <div class="col col-lg-6">
     <a routerLink="/session/register" class="btn btn-danger btn-block btn-lg btn-pill">{{'Register'|translate}}</a>
    </div>
    <div class="col col-lg-6">
     <a routerLink="/login" class="btn btn-secondary btn-block btn-lg btn-pill">{{'Login'|translate}}</a>
    </div>
   </div>
  </form>
  <!-- login form closed -->
 </div>
 <!-- login block closed -->
</div>
<!-- login wrapper closed -->