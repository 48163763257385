import { Component, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationStrategy } from '@angular/common';
import { CommonService } from './shared/common.service';
import { GlobalService } from './shared/global.service';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { environment } from 'environments/environment';

@Component({
  selector: 'epion-app',
  template: '<router-outlet></router-outlet>',
  encapsulation: ViewEncapsulation.None
})

export class EpionAppComponent implements OnInit {
  location: Location;
  constructor(translate: TranslateService, private locationStrategy: LocationStrategy,
    private commonService: CommonService,
    private globals: GlobalService) {

    translate.addLangs(['en', 'fr', 'he', 'ru', 'ar', 'zh', 'de', 'es', 'ja', 'ko', 'it', 'hu']);
    translate.setDefaultLang('en');

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    // Preventing forward button
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    // setInterval(() => {
    //  // Call To getCurrentDateTime from CommonController for Current Date and Time
    //  this.updateDateTime();
    // }, 1000 * 10);
    setInterval(() => {
      if (sessionStorage.getItem('token') !== null && (this.globals.CurrentDateTime || this.globals.CurrentDateTime == "")) {
        this.updateDateTime();
      }
    }, 1000);
  }
  updateDateTime() {
    this.globals.CurrentDateTime = new Date();
    setTimeout(() => {
      this.updateDateTime();
    }, 1000 * 1);
   
  }

  fetchDateTime() {
    this.commonService.getCurrentDateTime().subscribe((data) => {
      if (data) {
        this.globals.CurrentDateTime = data;
        // const now = moment(new Date()); // todays date
        // const end = moment(data); // another date
        // const duration = moment.duration(now.diff(end));
        // const seconds = duration.asSeconds();
        // if (seconds > -5 && seconds < 5) {
        //   setTimeout(() => {
        //     this.updateDateTime();
        //   }, 1000 * 1);
        // } else {
        //   this.fetchDateTime();
        // }
        this.updateDateTime();
        if (sessionStorage.getItem("settings") !== null) {
          sessionStorage.setItem("settings", btoa(JSON.stringify(this.globals)));
        }
      }
    });
  }

  ngOnInit(): void {
    // if (environment.production) {
    //   if (location.protocol == 'http:') {
    //     window.location.href = location.href.replace('http', 'https');
    //   }
    // }
      }
}