<!--Subscribe-->
<div class="registerone-wrapper subscribe-wrapper bg-sc d-flex align-items-center">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-11 col-xl-9 mx-auto">
				<div class="card shadow-box">
					<div class="card-header bg-primary">
						<h4 class="text-uppercase mb-0">{{'Subscribe'|translate}}</h4>
					</div>
					<div class="card-body">
						<form action="javascript:void(0)" method="post">
							<div class="row">
								<div class="col-12 col-sm-11 mx-auto">
									<p class="mb-3 text-dark">{{'Subscribe to our newsletter to receive weekly
										updates.'|translate}}</p>
									<div class="form-group">
										<label>{{'Your Email'|translate}}</label>
										<div class="form-row">
											<div class="input-group align-items-stretch col-12 col-md-11">
												<span class="input-group-addon bg-light px-3" id="basic-addon1"><i
														class="fa fa-envelope m-0 d-inline-block align-bottom"></i></span>
												<input type="text" class="form-control" placeholder="Username"
													aria-describedby="basic-addon1">
											</div>
										</div>
									</div>
									<div class="form-group">
										<button type="submit"
											class="btn btn-primary btn-square btn-lg">{{'Subscribe'|translate}}</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<!-- card body closed -->
			</div>
		</div>
	</div>
</div>
<!-- Coming wrapper closed -->