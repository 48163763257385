<!--Edit User list-->
<div class="userlist-popup bg-white px-4 py-4 shadow-box rounded mx-auto">
	<div class="grid-b-space">
		<h4 class="mb-0">{{'Edit User'|translate}}</h4>
	</div>
	<form [formGroup]="form">
		<div class="form-group grid-b-space">
			<input class="form-control" type="text" formControlName="firstName" placeholder="First name">
		</div>
		<div class="form-group grid-b-space">
			<input class="form-control" type="text" formControlName="lastName" placeholder="Last name">
		</div>
		<div class="form-group grid-b-space">
			<input class="form-control" type="email" formControlName="email" placeholder="Email">
		</div>
		<div class="form-group grid-b-space">
			<select class="form-control h-auto p-cursor custom-select" formControlName="accountType">
            <option>Platinum</option>
            <option>Gold</option>
            <option>Service</option>
         </select>
		</div>
	</form>
	<div class="d-flex">
		<button class="btn btn-lg bg-primary mr-3" (click)="activeModal.dismiss()">Close</button>
		<button class="btn btn-lg bg-light" (click)="onFormSubmit()">Submit</button>
	</div>
</div>