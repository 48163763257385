<!--Maintenance V2-->
<div class="maintenance-wrap py-5">
	<div class="container">
		<div class="main-row row no-gutters">
			<div class="col-12 col-sm-11 col-md-9 col-lg-9 mx-auto d-flex align-items-center">
				<div class="maintenance-content text-center shadow-box">
					<div class="sec-descp mb-4">
						<h2 class="font-weight-normal"><span class="font-2x">{{"We'll be back soon"|translate}}</span></h2>
					</div>
					<div class="sec-content text-center mrgn-b-2">
						<div class="row">
							<div class="col-12 col-sm-12 col-md-11 col-lg-11 mx-auto">
								<p class="mb-0">We're updating our servers to provide you with a better experience.Check back momentarily to view this page...or try hitting refresh, we may already be back!
								</p>
							</div>
						</div>
					</div>
					<div class="img-wrap mb-5">
						<img class="img-fluid" src="assets/img/maintain.png" width="300" height="37">
					</div>
					<div class="text-center d-flex align-items-center justify-content-center">
						<div class="mr-2">
							<input class="form-control" type="email" placeholder="Your Email">
						</div>
						<div>
							<button class="btn btn-primary text-nowrap">Get Notified</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>