<!--Under Maintenance-->
<div class="registerone-wrapper under-maintenance-wrapper bg-sc d-flex align-items-center justify-content-center">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-11 col-xl-9 mx-auto">
				<div class="card shadow-box">
					<div class="card-header bg-primary">
						<h4 class="text-uppercase mb-0">{{'Under maintenance'|translate}}</h4>
					</div>
					<div class="card-body">
						<form action="javascript:void(0)" method="post">
							<div class="row">
								<div class="col-12 col-sm-11 mx-auto">
									<p class="mb-3">{{"We are under maintenance right now. We'll be back
										soon."|translate}}</p>
									<p class="mb-2 text-dark">{{'Searching for something?'|translate}}</p>
									<div class="form-group mb-3 flex-row">
										<div class="input-group col-12 col-md-11 px-0">
											<span class="input-group-prepend">
												<button class="btn btn-primary" type="button">
													<i class="fa fa-search"></i>
												</button>
											</span>
											<input class="form-control" placeholder="Search" type="text">
										</div>
									</div>
									<div class="form-group mb-4">
										<button type="submit"
											class="btn btn-outline-primary btn-square btn-lg">{{'Submit'|translate}}</button>
									</div>
									<div class="links">
										<p class="text-dark mb-1">{{'Already have an account?'|translate}}
											<a routerLink="/login" class="text-primary">{{'Sign in here'|translate}}</a>
										</p>
										<p class="text-dark mb-0">{{'New user?'|translate}}
											<a routerLink="/session/register" class="text-primary">{{'Sign up
												here'|translate}}</a>
										</p>
									</div>
									<!-- links closed -->
								</div>
							</div>
						</form>
					</div>
				</div>
				<!-- Coming Soon Wrapper closed -->
			</div>
		</div>
	</div>
</div>
<!-- Coming wrapper closed -->