import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MenuToggleAnchorDirective, MenuToggleLinkDirective, MenuToggleDirective } from './menu-toggle';
import { MenuComponent } from './menu.component';

@NgModule({
 declarations: [
  MenuToggleAnchorDirective,
  MenuToggleLinkDirective,
  MenuToggleDirective,
  MenuComponent,
 ],
 exports: [
  MenuToggleAnchorDirective,
  MenuToggleLinkDirective,
  MenuToggleDirective,
  MenuComponent,
 ],
 imports: [
  CommonModule,
  FormsModule,
  TranslateModule,
 ]
})
export class MenuToggleModule { }
