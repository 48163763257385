import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "app/service/auth/auth.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
 constructor(private authService: AuthService) { }

 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  const token = this.authService.getToken(); // Get token from some service
  if (token) {
   request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }

  return next.handle(request);
 }
}
