import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';


@Injectable({
   providedIn: 'root'
})
export class CustomizeService {

   constructor(private http: HttpClient, public router: Router, private globals: GlobalService) { }

   // This method is used to display cutomized messages.
   // This method gives call to getMessages function from customizeService
   getMessages() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Message/GetMessages', { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   //  Loads all labels in different format.
   getLabels() {
      const customHeaders = this.globals.GetCustomHeadersForGet();
      return this.http.get<any>(environment.apiUrl + 'Custom/GetLabels', { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

   //   Saves Labels.
   saveCustomLabels(labels) {
      const customHeaders = this.globals.GetCustomHeadersForPost();
      return this.http.post<any>(environment.apiUrl + 'Custom/SaveLabels', labels, { headers: new HttpHeaders(customHeaders) })
         .pipe(map(data => data),
            catchError((error: any) => {
               throw error;
            }));
   }

}
