<!--Not Found-->
<div class="registerone-wrapper under-maintenance-wrapper bg-sc d-flex align-items-center not-found">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-11 col-xl-6 mx-auto">
				<div class="card not-found-wrapper text-center shadow-box p-4">
					<div class="mb-3">
						<h2 class="text-capitalize font-5x font-weight-bold">{{'404'|translate}}</h2>
					</div>
					<div class="mb-3">
						<h2 class="font-2x font-weight-bold text-danger">{{'Page not found'|translate}}</h2>
						<p class="font-lg">{{'Sorry! There might be some error on this page...'|translate}}</p>
					</div>
					<div class="mb-3">
						<a routerLink="/dashboard/dashboard-v1" class="btn btn-primary btn-large btn-pill">{{'Go back to Home'|translate}}</a>
					</div>
					<div class="mb-5">
						<p class="font-lg">{{'Stay connected, Subscribe us...'|translate}}</p>
					</div>
					<form action="javascript:void(0)" method="post">
						<div class="row">
							<div class="col-12 col-lg-8 mx-auto">
								<div class="form-group">
									<div class="input-group">
										<input class="form-control" placeholder="your email address" type="text">
										<span class="input-group-append">
                                 <button class="btn btn-primary h-100" type="button">Subscribe</button>
                              </span>
									</div>
								</div>
							</div>
						</div>
					</form>
					<!-- Subscribe form closed -->
				</div>
				<!-- coming soon wrapper closed -->
			</div>
		</div>
	</div>
</div>
<!-- Coming wrapper closed -->