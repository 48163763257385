<!--Delete User-->
<div class="userlist-popup bg-white px-4 py-4 shadow-box rounded mx-auto">
	<div class="mb-4">
		<h3 class="mb-2">Are You Sure You Want To Delete?</h3>
		<p class="mb-0">{{titleMessage}}</p>
	</div>
	<div class="d-flex">
		<button class="btn btn-lg bg-primary mr-3" (click)="activeModal.close(false)">Close</button>
		<button class="btn btn-lg bg-danger" (click)="activeModal.close(true);">Yes</button>
	</div>
</div>