<!--Add New User popup-->
<div class="userlist-popup mx-auto bg-white px-4 py-4 shadow-box rounded">
	<div class="grid-b-space">
		<h4 class="mb-0">{{'Add New User'|translate}}</h4>
	</div>
	<form [formGroup]="addNewUserForm">
		<div class="form-group grid-b-space">
			<input class="form-control" type="text" placeholder="First name" formControlName="firstName">
			<small *ngIf="addNewUserForm.controls['firstName'].hasError('required') && addNewUserForm.controls['firstName'].touched" class="text-danger">
	         You must include a first name.
	      </small>
		</div>
		<div class="form-group grid-b-space">
			<input class="form-control" type="text" placeholder="Last name" formControlName="lastName">
			<small *ngIf="addNewUserForm.controls['lastName'].hasError('required') && addNewUserForm.controls['lastName'].touched" class="text-danger">
	         You must include a last name.
	      </small>
		</div>
		<div class="form-group grid-b-space">
			<input class="form-control" type="email" placeholder="Email" formControlName="email" autocomplete="">
			<small *ngIf="addNewUserForm.controls['email'].hasError('required') && addNewUserForm.controls['email'].touched" class="text-danger">
	         You must include an email address.
	      </small>
			<small *ngIf="addNewUserForm.controls['email'].errors && !(addNewUserForm.controls['email'].hasError('required')) && addNewUserForm.controls['email'].touched" class="text-danger">
	         You must include a valid email address.
	      </small>
		</div>
		<div class="form-group grid-b-space">
			<select class="form-control h-auto p-cursor custom-select" formControlName="accountType">
            <option>Platinum</option>
            <option>Gold</option>
            <option>Silver</option>
         </select>
			<small *ngIf="addNewUserForm.controls['accountType'].touched && !addNewUserForm.controls['accountType'].valid">
	         <small *ngIf="addNewUserForm.controls['accountType'].hasError('required')" class="text-danger">  
	         You must Select a Account Type.
	         </small>
			</small>
		</div>
	</form>
	<div class="d-flex">
		<button class="btn btn-lg bg-primary mr-3" (click)="activeModal.dismiss()">Close</button>
		<button class="btn btn-lg bg-light" (click)="onFormSubmit()">Submit</button>
	</div>
</div>