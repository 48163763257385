import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../service/auth/auth.service';

@Injectable({
 providedIn: 'root'
})

export class AuthGuard implements CanActivate {
 constructor(
  private router: Router,
  private authService: AuthService) { }

 canActivate() {

  if (this.authService.getLocalStorageUser()) {
   // logged in so return true
   return true;
  }

  // not logged in so redirect to login page
  this.router.navigate(['/']);
  return false;
 }
}
